<template>
  <div class="caseVue">
    <header class="header">
      <!-- <h1>{{ department }}</h1> -->
      <h3 class="animated fadeInDown">{{hData.enname}}</h3>
      <h5 class="animated fadeInUp">{{hData.cateDescription}}</h5>
    </header>
    <main>
      <b-container fluid="xl">
        <b-row class="mb-4">
          <b-col md="4" v-for="item in datas" :key="item.id">
            <router-link
              class="hover"
              :to="{ path: '/quarterlyFile/programme/' + item.id }"
            >
              <div class="caseItem rounded wow fadeInUp">
                <div class="img">
                  <b-img
                    fluid
                    :src="$imgUrl + item.imagePath"
                    alt="item.title"
                  ></b-img>
                </div>
                <div class="title">{{ item.title }}</div>
                <div class="content">
                  {{ item.description }}
                </div>
              </div>
            </router-link>
          </b-col>
        </b-row>
      </b-container>
      <section class="case">
        <b-container fluid="xl">
          <b-row class="mb-1">
            <b-col md="12" class="content container">
              <h1 style="">
                我们拥有信息技术开发、技术咨询、技术服务、政务系统信息化、大数据汇聚整合加工可视化价值变现等核心技术能力，为政府、学校、医院
                、企业提供领先的信息化系统建设及解决方案的服务。
              </h1>
              <h2>
                <!-- 了解TimeCamp的所有时间跟踪基础知识，并像真正的专家一样跟踪时间。 -->
              </h2>
              <button class="liuguang" v-b-modal.modal-prevent-closing>
                演示预约
              </button>
            </b-col>
          </b-row>
        </b-container>
      </section>
    </main>
    <Contact />
  </div>
</template>

<script>
import Contact from "../components/Contact";
import { WOW } from "wowjs";
export default {
  //import引入的组件
  components: { Contact },
  // props: ["department"],
  data() {
    return {
      headerHeight: "",
      datas: [],
      hData: {
        enname: "解决方案",
        cateDescription: "场景化、智慧化的行业数字化解决方案",
      },
    };
  },
  //方法集合
  methods: {
    async getData() {
      let _this = this;
      await _this.$http.get(`/AllSolution`).then((e) => {
        console.log(e);
        if (e.status == 200) {
          _this.hData = e.data.hostData;
          _this.datas = e.data.product;
        }
      });
      // 动画重启
      this.$nextTick(() => {
        // 在dom渲染完后,再执行动画
        var wow = new WOW({
          live: false,
        });
        wow.init();
      });
    },
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    this.getData();
  },
  watch: {},
};
</script>
<style lang="scss" scoped>
main {
  margin-top: 8rem;
}
.hover {
  color: black;
}
.caseItem {
  // background-color: #fff;
  // box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  // padding: 1.5rem !important;
  // // margin: 0;
  // margin-bottom: 20px;
  // min-height: 460px;
  // position: relative;
  // overflow: hidden;
  // cursor: pointer;
  position: relative;
  text-align: center;
  padding-top: 60px;
  // padding-bottom: 20px;
  border-bottom: 1px solid #c9c9c9;
  height: 100%;
  overflow: hidden;
  & > * {
    margin-bottom: 10px;
  }
  .title {
    font-size: 1.4rem;
    font-weight: 700;
    text-align: center;
  }
  .content {
    text-indent: 2rem;
    text-align: justify;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 5;
    overflow: hidden;
  }
  .img {
    border: 1px solid #e2e2e2;
    overflow: hidden;
    height: 50%;
    // width: calc(100% - 3rem);
    // position: absolute;
    // bottom: 1.5rem;
    // left: 1.5rem;
    // right: 1.5rem;
    // overflow: hidden;
    // height: 236px;
    // display: flex;
    // justify-content: center;
    // align-items: center;
    img {
      width: 100%;
      transition: 2s;
    }
  }
  &::before,
  &::after {
    content: "";
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 0;
    border-bottom: 2px solid #409EFF;
    transition: 1s;
  }
  &::after {
    left: auto;
    right: 0;
  }
  &:hover {
    // transform: scale(1.3) rotate(4deg);
    // transform: scale(1.3);
    // transition: ;
    &::before,
    &::after {
      width: 100%;
    }
    .img {
      img {
        transform: scale(1.2);
      }
    }
  }
}
</style>